body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  max-height: 100%;
}

html, 
body,
.App,
#root {
    height: 100%;
}


.background {
  background-image: url('./background.png');
  opacity: 0.06;
  background-repeat: repeat;
  height: 100%;
  max-height: 100%;
}